import React, { useEffect, useState } from "react";
import './Dashboard.css';
import Sidebar from "../Dashboard/Sidebar/Sidebar";
import { Route, Routes } from "react-router-dom";
import Popup from "reactjs-popup";
import Logoutpopup from "../../../pages/Login/Logoutpopup";
import { truncate } from "fs";

interface LoginProps {
    onLogOut: (user: any) => void; // Define the type of onLogin function
}

const Dashboard: React.FC<LoginProps> = ({ onLogOut }) => {

    const [showlogoutPopup, setlogoutShowPopup] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const resetTimeout = () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                setlogoutShowPopup(true);
            }, 120000);
        };

        const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        const resetTimeoutHandler = () => resetTimeout();

        events.forEach(event => {
            document.addEventListener(event, resetTimeoutHandler);
        });


        resetTimeout();


        return () => {
            events.forEach(event => {
                document.removeEventListener(event, resetTimeoutHandler);
            });
            clearTimeout(timeout);
        };
    }, []);

    return (
        <>
            <Sidebar onLogOut={onLogOut} />

            {/* <Popup contentStyle={{ height: 245, width: 350, padding: 0 }} open={showlogoutPopup} onClose={() => setlogoutShowPopup(false)} >
                <Logoutpopup openClose={() => setlogoutShowPopup(false)} hadleLogout={onLogOut} />
            </Popup> */}
            <Popup contentStyle={{ height: 245, width: 350, padding: 0 }} open={showlogoutPopup} onClose={() => setlogoutShowPopup(false)} >
                <Logoutpopup openClose={() => setlogoutShowPopup(false)} hadleLogout={onLogOut} />
            </Popup>
        </>
    );
}

export default Dashboard;