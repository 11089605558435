// customToast.js

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const customToast = {
  success(msg : any, options = {}) {
    return toast.success(msg, {
      ...options,
       className: `toast-${"green"}`
    //className: 'toast-warning'

    });
  },

  Warning(msg: any, options ={}) {
    return toast.warning(msg, {
      ...options,
      className: `toasr-${"red"}`
    });
  },

  error(msg: any, options = {}) {
    return toast.error(msg,{
      ...options,
      className: `toast-${"red"}`
    })
  }
  
};

export default customToast;
