import React, { useEffect, useState } from "react";
import EnhancedPage from "../../Commen_Component/EnhancedPage";
import { PageEnums } from "../../../_enums/enum.services";

const User = () => {
    const [delData, setDelData] = useState();
    // useEffect(() => {
    //     console.log(delData,'deldata');
        
    // },[delData])

    return <EnhancedPage pageId={PageEnums.User} deleteData={setDelData} refTable={false}/>
}

export default User;