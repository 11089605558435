import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userServicer } from "../../App/Commen_Services/user.services";
import customToast from "../../App/Commen_Component/Toast/CustomToast";
import './Login.css';
import background from "../../assets/Images/login_bg_1.png";
import Logo from "../../assets/Images/image_2024_06_18T11_03_16_909Z.png"
import { Lock, Person, VerifiedUser } from "@mui/icons-material";

interface LoginProps {
    onLogin: (user: any) => void; // Define the type of onLogin function
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {

    let navigate = useNavigate();
    const [progress, setProgress] = React.useState(0);
    const [loadProgress, setLoadProgress] = React.useState(false);
    const [inputs, setInputs] = useState<any>({});
    const [error, setError] = useState({
        userName: '',
        password: ''
    });

    React.useEffect(() => {
        if (loadProgress) {
            setProgress(100);
            // const timer = setInterval(() => {
            //     setProgress((oldProgress) => {
            //         if (oldProgress === 100) {
            //             return 0;
            //         }
            //         const diff = Math.random() * 10;
            //         return Math.min(oldProgress + diff, 100);
            //     });
            // }, 500);
            // return () => {
            //     clearInterval(timer);
            // };

        }
        else {
            setProgress(0);
        }
    }, [loadProgress]);

    const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoadProgress(true);

        if (!inputs.userName) {
            setError((prevErrors) => ({ ...prevErrors, userName: "Please enter Username" }));
        }
        if (!inputs.password) {
            setError((prevErrors) => ({ ...prevErrors, password: "Please enter Password" }));
        }
        if (inputs.userName !== undefined && inputs.password !== undefined) {
            if (error.userName === '' && error.password === "") {
                try {

                    userServicer.login(inputs).then(user => {
                        console.log('user.status', user);
                        setLoadProgress(false);
                        if (user !== undefined) {
                            if (user.statusCode === 200) {
                                if (user.status === true) {

                                    //localStorage.setItem("user", user.data);
                                    onLogin(user);
                                    setInputs({});
                                    navigate('/');
                                }
                                else {
                                    // dispatch(loaderActions.end());
                                    // dispatch(alertActions.error(user.message));
                                    customToast.error(user.message);
                                }
                            }
                        }
                        
                    });
                }
                catch (ex) {

                    console.log("Error : ", ex);

                }

            }
        }




    };

    const handelSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        // console.log(name + ":" + value);
        setInputs((values: any) => ({ ...values, [name]: value }))
        setError((prevError) => ({ ...prevError, [name]: "" }));
    };

    const handleFocus = (fieldName: any) => {

        if (!inputs[fieldName]) {
            setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter " + fieldName }));
        }
    };

    return (<>
        <div
            style={{ display: `${loadProgress ? '' : 'none'}` }}
        ><LinearProgress variant="determinate" value={progress} /></div>

        <div className="login_main"
        // style={{ backgroundImage: `url(${background})` }}
        >
            <div className="login_card">
                <div className="login_1">
                    <img src={Logo} className="login_img" alt="logo" ></img>
                </div>
                <div className="login_2">
                    <div className="login_header">
                        <h3>SIGN IN</h3>
                    </div>
                    <form onSubmit={submitLogin}>
                        <div className="login_content">


                            <FormControl sx={{ width: '85%' }} variant="standard">
                                <InputLabel sx={{ fontSize: 12 }} htmlFor="standard-adornment-password">Username</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type="text"
                                    name="userName"
                                    onFocus={() => handleFocus('userName')}

                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton sx={{ color: '#243C5C' }}
                                                aria-label="toggle password visibility">
                                                <Person />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onChange={handelSubmit}
                                    error={!!error.userName}


                                />
                                {error.userName ? <FormHelperText sx={{ color: 'red', fontSize: 10 }}>{error.userName}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 10 }}>username</FormHelperText>}

                            </FormControl>

                            <FormControl sx={{ width: '85%' }} variant="standard">
                                <InputLabel sx={{ fontSize: 12 }} htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type="password"
                                    //type={showPassword ? 'text' : 'password'}
                                    onChange={handelSubmit}
                                    name="password"
                                    onFocus={() => handleFocus('password')}
                                    error={!!error.password}
                                    sx={{
                                        "input::-ms-reveal,input::-ms-clear": {
                                            "display": "none"
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                sx={{ color: '#243C5C' }}
                                            // onClick={handleClickShowPassword}
                                            //onMouseDown={handleMouseDownPassword}
                                            >
                                                <Lock />

                                                {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />
                                {error.password ? <FormHelperText sx={{ color: 'red', fontSize: 10 }}>{error.password}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 10 }}>username</FormHelperText>}
                            </FormControl>
                            <div >
                                <button className="login_btn" type="submit" onClick={() => submitLogin}><span><VerifiedUser /> &nbsp; SIGN IN</span></button>

                            </div>


                        </div>
                    </form>

                </div>
            </div>
        </div>
    </>);
}


export default Login;