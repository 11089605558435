import { commanServicer } from "../Commen_Services/common.services";

export const PositionMasterService = {
    getPositionById,
    getPositionDropDownData,
    saveOrUpdatePositionData,  
    getTreeData,
    deleteById,
}

async function getPositionById(id : any) {
    return await commanServicer.fetchRequest('',true,'PositionMaster/retrive?PositionId='+id,'GET');
}
async function getPositionDropDownData() {
    return await commanServicer.fetchRequest('', true, 'PositionMaster/positionlist', 'GET');
}

async function saveOrUpdatePositionData(data:any) {
    return await commanServicer.fetchRequest(JSON.stringify(data),true,'PositionMaster/saveupdate', 'POST');
}

async function getTreeData() {
    return await commanServicer.fetchRequest('', true, 'PositionMaster/viewtree', 'GET');
}

async function deleteById(id : any) {
    return await commanServicer.fetchRequest('',true,'PositionMaster/delete?PositionId='+id,'GET');
}

