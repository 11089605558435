import User from "../App/Admin/UserMaster/User";

export const  AccessLevel =  {

  
  View : 1,
  New : 2,
  Edit : 4,
  Delete : 8,
  Filter : 16,
  Export : 32,
  Send : 64,
  ViewHistory: 128,
  AddNew: 256,
  EditNew: 512,
  ViewNew:1024,
  Print : 2048,
  Tree : 4096,
};

export const InputType = {
    None : 0,
    Text : 1,
    Email : 2,
    Phone : 3,
    Mobile :4,
    Number : 5,
    Date : 6,
    IsActive : 7,
    Amount : 8,
    Dropdown : 9,
    HTML : 10,
    Index : 11,
    DateTime : 12,
    SubReport : 13,
    MultiSelect :14,
};

export const PageEnums = {

  User:3,
  Position:4,
};


export const MasterPrimaryKeys = {
User:"id",
Position:"postionId",
}

