import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../../pages/PageNotFound';

const componentPaths:any = {
    "6": PageNotFound 
    
};

const AddorEdit = () => {

    const { pageId } = useParams<{ pageId: string }>();
    const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);

    useEffect(() => {
        
        const importComponent = async () => {
                      
                // console.log(pageId);
                if( pageId !== undefined)
                {
                    if (componentPaths[pageId]) {
                        setComponent(() => componentPaths[pageId]);
                    } 
                    else
                    {
                        setComponent(() => null)
                    }
                    
                }           
        };

        importComponent();
    }, [pageId]);

    if (!Component) return <PageNotFound />;

    return <Component />;
};

export default AddorEdit;




