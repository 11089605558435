import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { PositionMasterService } from "../../Commen_Services/Position.service";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import "./TreePositionView.css";


interface TreeNode {
  PositionId: number;
  PositionName: string;
  children?: TreeNode[];
}

interface TreeNodeProps {
  node: TreeNode;
  selectedNodeId: number | null;
  onNodeSelect: (nodeId: number) => void;
  expandPosition: number;
}

const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  selectedNodeId,
  onNodeSelect,
  expandPosition,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    node.PositionId <= expandPosition
  );

  const isSelected = selectedNodeId === node.PositionId;

  const handleNodeSelect = () => {
    onNodeSelect(node.PositionId);
    console.log(node.PositionId);
  };

  const hasChildren = node.children && node.children.length > 0;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const { componentName } = useParams<{ componentName: string }>();

  return (
    <div className="tree-node">
      <div className="node-content">
        {hasChildren && (
          <button className="expand-button" onClick={handleToggle}>
            <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} />
          </button>
        )}
        <label className="node-label ">
          <input
            type="radio"
            name="treeNode"
            className={componentName === "User" ? '' : 'inactive'}
            checked={isSelected}
            onChange={handleNodeSelect}
          />
          <span>{node.PositionName}</span>
        </label>
      </div>
      {isExpanded && node.children && (
        <ul className="children">
          {node.children.map((childNode) => (
            <li key={childNode.PositionId}>
              <TreeNode
                node={childNode}
                selectedNodeId={selectedNodeId}
                onNodeSelect={onNodeSelect}
                expandPosition={expandPosition} 
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const TreePositionView: React.FC<{ sendDatatoParent: any, selectedPositionId: any, close: any  }> = ({ sendDatatoParent, selectedPositionId, close }) => {
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);
  const [treeData, setTreeData] = useState<TreeNode | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await PositionMasterService.getTreeData();

        console.log(response[0]);

        setTreeData(response[0]);
      } catch (error) {
        console.error('Error fetching tree data:', error);
      }
    };

    fetchData();
  }, []);

  const handleNodeSelect = (nodeId: number) => {
    setSelectedNodeId(nodeId);
  };
  useEffect(() => {
    sendDatatoParent(selectedNodeId);

  }, [selectedNodeId])

  useEffect(() => {
    setSelectedNodeId(selectedPositionId);
  }, [selectedPositionId]);

  if (!treeData) {
    return <div>Loading...</div>;
  }


  const closePopup = () => {
    close(false)

}

  return (
    <>
      <div className="pop-head treePopup">
        <div>
          <span className="pop-form">
            &nbsp;
            <AccountTreeIcon />
            &nbsp;&nbsp;Position View
          </span>
        </div>
        <div>
          <button style={{ border: 'none', backgroundColor: '#B3C9E6', cursor: 'pointer', paddingRight:10}} onClick={closePopup} ><CloseIcon /></button></div>
      </div>
      <div className="tree-container">
        <TreeNode
          node={treeData}
          selectedNodeId={selectedNodeId}
          onNodeSelect={handleNodeSelect}
          expandPosition={selectedPositionId} 
        />
      </div>
    </>
  );
};

export default TreePositionView;
