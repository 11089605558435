import React, { useState, useEffect } from "react";   
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { Booth } from "../BoothMaster/types"; 
import "./Booth.css";

interface BoothMappingDialogProps {
  open: boolean;
  onClose: () => void;
  booths: Booth[];
  assignedBooths: Booth[];
  onAssign: (booths: Booth[]) => void;
  onRemoveBooth: (booth: Booth[]) => void;
}

const BoothMappingDialog: React.FC<BoothMappingDialogProps> = ({
  open,
  onClose,
  booths,
  assignedBooths,
  onAssign,
  onRemoveBooth,
}) => {
  const [selectedBooths, setSelectedBooths] = useState<Booth[]>([]);
  const [boothSearch, setBoothSearch] = useState("");
  const [tempRemoveData, setTempRemoveData] = useState<Booth[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (open) {
      if (selectedBooths.length === 0) {
        setSelectedBooths(assignedBooths);
      }
    } else {
      setSelectedBooths([]);
    }
  }, [open, assignedBooths]);

  

  const handleBoothChange = (booth: Booth) => {
    const isSelected = selectedBooths.some(b => b.boothId === booth.boothId);
    const updatedBooths = isSelected
      ? selectedBooths.filter(b => b.boothId !== booth.boothId)
      : [...selectedBooths, booth];
    setSelectedBooths(updatedBooths);
  };

  const handleAssign = () => {
    const boothIdsToRemove = new Set(selectedBooths.map(b => b.boothId));
    const updatedTempRemoveData = tempRemoveData.filter(b => !boothIdsToRemove.has(b.boothId));
    setTempRemoveData(updatedTempRemoveData);
    onRemoveBooth(updatedTempRemoveData); 
    onAssign(selectedBooths);
    onClose();
  };

  const handleRemoveBooth = (booth: Booth) => {
    setSelectedBooths(selectedBooths.filter(b => b.boothId !== booth.boothId));
    setTempRemoveData(prevData => {
      const exists = prevData.some(b => b.boothId === booth.boothId);
      if (!exists) {
        return [...prevData, booth];
      }
      return prevData;
    });
  };

  const filteredBooths = booths != null || booths != undefined ? booths.filter(booth =>
    booth.boothNo.toString().includes(boothSearch)
  ) : [];

  useEffect(() => {
    const allBooths = filteredBooths.map(b => b.boothId);
    const selectedBoothIds = selectedBooths.map(b => b.boothId);
    if (selectAll) {
      setSelectedBooths(filteredBooths);
    } else if(selectAll && allBooths.length != selectedBoothIds.length){
        setSelectedBooths(prevState => 
          prevState.filter(booth => filteredBooths.some(b => b.boothId === booth.boothId))
        
        );  
      }
     
      
      //
    else if(selectAll == false && allBooths.length == selectedBoothIds.length){
      setSelectedBooths([]);
    }
  }, [selectAll]);

  useEffect(() => {
    const allBooths = filteredBooths.map(b => b.boothId);
    const selectedBoothIds = selectedBooths.map(b => b.boothId);
    if(selectAll && allBooths.length != selectedBoothIds.length)
    {
    setSelectAll(false);
    }
    if(allBooths.length == selectedBoothIds.length && selectAll == false)
    {
      setSelectAll(true)
    }
    
  }, [selectedBooths]);

  return (
    <Dialog open={open} className="booth-container" onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="dialog-title">
        <i
          className="fa fa-link fa-lg"
          aria-hidden="true"
          style={{ marginRight: 10 }}
        ></i>
        User Booth Mapping
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Search Booth Number &nbsp; &nbsp;"
              variant="outlined"
              fullWidth
              className="search-field"
              value={boothSearch}
              onChange={(e) => setBoothSearch(e.target.value)}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
            <FormGroup className="list-container">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => setSelectAll(e.target.checked)}
                  />
                }
                label="Select All"
                style={{ marginBottom: 10 , borderBottom: '1px solid #ddd'}}
              />
              {filteredBooths.map((booth) => (
                <FormControlLabel
                  key={booth.boothId}
                  style={{ borderBottom: '1px solid #ddd' }}
                  control={
                    <Checkbox
                      checked={selectedBooths.some(b => b.boothId === booth.boothId)}
                      onChange={() => handleBoothChange(booth)}
                    />
                  }
                  label={`Booth No : ${booth.boothNo}`}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Assigned Booths ({selectedBooths.length})
            </Typography>
            <div className="list-container">
              {selectedBooths.map((booth) => (
                <Box key={booth.boothId} className="assigned-booth">
                  <Typography>Booth No : {booth.boothNo}</Typography>
                  <IconButton onClick={() => handleRemoveBooth(booth)}>
                    <CancelIcon />
                  </IconButton>
                </Box>
              ))}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="actions">
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAssign} variant="contained" color="primary">
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BoothMappingDialog;



