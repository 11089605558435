import { Add, Close, Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Autocomplete, FormControl, FormHelperText, Link, TextField, InputLabel, MenuItem, Select } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import customToast from "../../Commen_Component/Toast/CustomToast";
import { userServicer } from "../../Commen_Services/user.services";
import Popup from "reactjs-popup";
import { PositionMasterService } from "../../Commen_Services/Position.service";

interface propsHead {
    columndata: any[];
    editRowData: RowData[];
    sendDatatoParent: any;
    refreshGrid: any;
    sendViewStatus: any;
}

interface RowData {
    [key: string]: any;
}

const AddPosition: React.FC<propsHead> = ({ columndata, editRowData, sendDatatoParent, refreshGrid, sendViewStatus }) => {
    const [inputs, setInputs] = useState<any>({});
    const [disable, setDisable] = useState(true);
    const [editable, setEditable] = useState(true);
    const [visibleUPdate, setVisibleUpdate] = useState(false);
    const [selectedParentPositionId, setSelectedParentPositionId] = useState();
    const [dropdownPosition, setdropdownPosition] = useState([{ OptionID: 0, OptionName: "--Select Position--" }]);
    const [error, setError] = useState({
        positionName: '',
        parentPositionId: '',
    });
    const [handleCancel, setCancel] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isSave, setIssave] = useState(false);
    const [use, setUse] = useState(true);
    const [updateDropDown, setupdateDropDown] = useState(false);



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;
        setInputs((values: any) => ({ ...values, [name]: value }))
        let newError: string;
        if (name === 'positionName') {
            // if (!/^[a-zA-Z0-9]{1,50}$/.test(value)) {
            //     newError = "Name should be Alphabetic & maximum of 50 characters";
            // }
            if (value.trim() === '') {
                newError = "Please Enter Position.";
            }
            if (!/^[a-zA-Z0-9]{1,15}$/.test(value)) {
                newError = "Please enter alphanumeric Position Name & Maximum length of 15 characters"
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, positionName: newError }));
        }

        setVisibleUpdate(false);
    };

    const handleFocus = (fieldName: any) => {
        if (!inputs[fieldName]) {
            setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter " + fieldName }));
        }
    };

    useEffect(() => {                                                       
        const fetchPosition = async () => {

            
            let positionDropDownData = await PositionMasterService.getPositionDropDownData();

            let positionList = positionDropDownData.data;
            const transformedPosition = positionList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(positionList[0])[0]],
                OptionName: item[Object.keys(positionList[0])[1]] == undefined ? item[Object.keys(positionList[0])[2]] : item[Object.keys(positionList[0])[1]]
            }));

            setdropdownPosition(transformedPosition);

        }
        fetchPosition()

    }, [updateDropDown])       

    let valPosition = { OptionName: '' };

    if (dropdownPosition !== undefined && dropdownPosition !== null && inputs?.["parentPositionId"] !== undefined && inputs?.["parentPositionId"] !== null) {
        let valPosition2 = dropdownPosition.filter(item => item.OptionID === inputs?.["parentPositionId"]);

        let valPosition4 = valPosition2.length > 0 ? Object.values(valPosition2[0]) : '';

        valPosition.OptionName = valPosition4[1] !== undefined ? String(valPosition4[1]) : '';

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {
        setIssave(true);
        setShowConfirmation(false);
        handleCancel ? setCancel(false) : setCancel(true);


    };

    const handelSubmit = (event: any, isSav: any) => {
        event.preventDefault();


        if (!inputs.positionName) {
            setError((prevErrors) => ({ ...prevErrors, positionName: "Please enter Position" }));
        }
        if (!inputs.parentPositionId) {
            setError((prevErrors) => ({ ...prevErrors, parentPositionId: "Please Select Parent Position" }));
        }

        if (inputs.positionName !== undefined && inputs.parentPositionId !== undefined && inputs.positionName !== '' && inputs.parentPositionId !== '') {
            if (error.positionName === '' && error.parentPositionId === '') {
                try {
                    PositionMasterService.saveOrUpdatePositionData(inputs).then(user => {

                        if (user.statusCode === 200) {
                            if (user.status === false) {
                                customToast.Warning(user.message);
                                closeForm(isSav);
                                refreshGrid(true);
                            }
                            else {
                                customToast.success(user.message);
                                closeForm(isSav);
                                refreshGrid(true);
                               updateDropDown ? setupdateDropDown(false) : setupdateDropDown(true);
                            }                     
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }
        }
    }

    const closeForm = (isSav: any) => {
        setVisibleUpdate(false);
        setInputs({ positionName: '', parentPositionId: '' });
        setError({
            positionName: '',
            parentPositionId: ''
        });
        sendDatatoParent("");
        sendViewStatus(false);
        if (isSav) {
            setDisable(true);
            setEditable(true);
        }

    }

    useEffect(() => {

        closeForm(true);


    }, [handleCancel])

    useEffect(() => {

        const getData = async (id: any) => {
            let value = await PositionMasterService.getPositionById(id);
            editRowData = value.data;
            setInputs(editRowData);
        }

        setInputs((values: any) => ({ ...values, positionName: '', parentPositionId: 0 }));
        if ((editRowData as any) !== '') {
            getData((editRowData as any).positionId);
            setVisibleUpdate(true);
            setDisable(false);
            (editRowData as any).isViewData ? setEditable(true) : setEditable(false);

        }
    }, [editRowData, use]);

    return (
        <>
            <div style={{ marginLeft: 10, marginBottom: -17 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                        sx={{ marginRight: 1 }}
                        id="standard-basic"
                        variant="standard"
                        name="positionName"
                        value={inputs?.["positionName"]}
                        label="Position"
                        disabled={disable}
                        onChange={handleChange}
                        focused={!!inputs.positionName}
                        onFocus={() => handleFocus("positionName")}
                        error={!!error.positionName}
                        helperText={error.positionName}
                        InputProps={{
                            readOnly: editable,
                            style: {
                                color: '#243C5C',
                                fontSize: '14px',
                                width: '200px',
                                borderColor: error.positionName ? 'red' : undefined
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#243C5C',
                                fontSize: '12px',
                                borderColor: error.positionName ? 'red' : undefined
                            },
                        }}
                        FormHelperTextProps={{
                            style: {
                                fontSize: error.positionName ? '9px' : '12px',
                                maxWidth: '210px',

                            },
                        }}
                    />
                    <FormControl>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dropdownPosition}
                            value={valPosition}
                            disabled={disable}
                            // readOnly={disable}
                            readOnly={editable}
                            onFocus={() => handleFocus("parentPositionId")}
                            onChange={(event, newValue: any) => {
                                setVisibleUpdate(false);
                                setInputs((values: any) => ({ ...values, parentPositionId: newValue != null ? newValue.OptionID : '' }));
                                setSelectedParentPositionId(newValue);
                                setError((prevErrors) => ({ ...prevErrors, parentPositionId: '' }));
                            }}
                            getOptionLabel={(option) => option.OptionName}
                            getOptionKey={(option) => option.OptionID}
                            sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden', marginRight: 1 }}
                            renderInput={(params) => (
                                <TextField
                                    error={!!error.parentPositionId}
                                    {...params}
                                    label="Parent Position"
                                    variant="standard"
                                    name="parentPositionId"
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: 'red',
                                        },
                                    }}
                                />
                            )}
                        />
                        {error.parentPositionId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.parentPositionId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.parentPositionId}</FormHelperText>}
                    </FormControl>

                    <button disabled={editable ? false : visibleUPdate ? true : false} className={`btn123 ${disable || editable ? 'inactive' : ''} ${visibleUPdate ? 'btndisable' : ''}`} onClick={(event) => { (editRowData as any) != '' ? handelSubmit(event, true) : handelSubmit(event, false); }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) != '' ? <>Update</> : <>Save&nbsp;&&nbsp;New</>}</button>&nbsp;
                    <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={(event) => { setIssave(true); handelSubmit(event, true); }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button>               
                    <button className={`btn123 ${disable ? '' : 'inactive'}`} onClick={() => {
                        setDisable(false); setEditable(false);
                        (editRowData as any) = "";
                        setInputs({});
                        setUse(false);

                    }} style={{ paddingRight: 12 }}><Add />&nbsp;Add</button>&nbsp;
                    &nbsp;

                    <button className={`btn123 ${disable ? 'inactive' : ''}  `} onClick={() => { setShowConfirmation(true) }}  ><Close />&nbsp;Close</button>
                    {showConfirmation && (
                        <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                            <div className="confirmation-modal">
                                <p>Are you sure you want to exit? <br />(Any unsaved changes will be lost.)</p>
                                <div className="canel_btns">
                                    <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
                                    <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Yes, Exit</button>
                                </div>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>

        </>
    );
}

export default AddPosition;