import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EnhancedSearchbar from "../Commen_Component/EnhancedSearchbar";
import { FaUser } from "react-icons/fa";
import EnhancedTable from "./EnhancedTable";
import { userServicer } from "../Commen_Services/user.services";
import { AccessLevel, PageEnums } from "../../_enums/enum.services";
import { Hidden } from "@mui/material";
import AddPosition from "../Admin/PositionMaster/AddPosition";


interface ColumnData {
    autoSearchKey: string;
    tableFieldName: string;
    headerName: string;
}

interface RowData {
    [key: string]: any;
}

interface ReportConfigData {
    viewButtonsSum: number;
}

const EnhancedPage: React.FC<{ pageId: any, deleteData:any ,refTable: boolean}> = ({ pageId, deleteData , refTable}) => {
    
    const { paramPageId } = useParams();
    const { componentName, param1 } = useParams();
    let updatedPageID = pageId || paramPageId;
    const [columnData, setColumnData] = useState<ColumnData[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [pageCount, setPageCount] = useState(0);
    const [tableData, setTableData] = useState<RowData[]>([]);
    const [csvData, setCSVData] = useState<RowData[]>([]);
    const [reportConfigData, setreportConfigData] = useState<any>([]);
    // const [sortConfig, setSortConfig] = useState<{ key: string; direction: string }>({
    //     key: '',
    //     direction: 'asc',
    // });
    useEffect(() => {
        // console.log(reportConfigData, 'reportConfigData')
    }, [reportConfigData])
    const [firtpageNum, setfirstPageNum] = useState(1);
    const [secondpagenum, setSecondPagenum] = useState(perPage);
    const navigate = useNavigate();
    const [viewButtonSum, setButtonSum] = useState<number[]>([]);

    //const [dataFromChild, setDataFromChild] = useState<RowData>({});
    const [filterFormData, setFilterFormData] = useState<{ key: string; value: any }[]>([]);
    const [defaultSeacrh1, setdefaultSeacrh] = useState<{ key: string; value: any }[]>([]);
    const [fullWidth, setFullWidth] = useState(false);
    //const [first, setFirst] = useState(false);
    const handleFilterData = (event: { key: string; value: any }[]) => {

        // console.log(event);
        setFilterFormData(event);

    };




    const [tableColumns, settableColumns] = React.useState<RowData[]>(columnData);
    const [editRowData, setEditRowData] = React.useState<any>();
    const [refreshData, setRefreshData] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({});
    const [isViewStatus, setIsViewStatus] = React.useState({});
    const [isDefaultSearch, setIsDefaultSearch] = React.useState(false);

    const handleViewStatus = (item: any) => {
        let data = { isViewStatus: item }
        setIsViewStatus(data);
    }

    const handleEditButtondata = (event: RowData[]) => {
        // console.log(event);

        setEditRowData(event);
    }



    const handlePagination = (event: any) => {
        // console.log(event);
        setPaginationData(event);
        // console.log(paginationData);


    }

    const handleDeleteButtondata = (event: any) => {
        // console.log(event);
        deleteData(event);
        //setEditRowData(event);
    }

    
useEffect(() => {

   //setRefreshData(refTable);
   setRefreshData(!refreshData)

},[refTable])

    useEffect(() => {
        const t1 = localStorage.getItem('isHidden');
        t1 == 'false' ? setFullWidth(false) : setFullWidth(true);

    }, [localStorage.getItem('isHidden')]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [tableConfig, reportConfig] = await Promise.all([
                    userServicer.tableConfig(updatedPageID),
                    userServicer.reportConfig(updatedPageID)
                ]);
                setColumnData(tableConfig.data);
                setreportConfigData(reportConfig.data);
                setButtonSum(findSumElements(reportConfig.data.viewButtonsSum));
                let def = reportConfig.data.defaultSearchList;
                // console.log("def" ,def.length);
                
                if (def.length > 0) {
                    let defaultSearch = [];
                    defaultSearch.push({ "key": def[0].defaultSearchField, "value": def[0].defaultSearchFieldValue });
                    //  console.log(defaultSearch);
                    setIsDefaultSearch(true);
                    setFilterFormData(defaultSearch);
                    setdefaultSeacrh(defaultSearch);
                }
                else {
                    let defaultSearch: any[] = [];
                    setIsDefaultSearch(false);
                    setFilterFormData(defaultSearch);
                    setdefaultSeacrh(defaultSearch);
                }
            } catch (error) {
                //console.error('Error fetching data:', error);
                navigate("/Pagenotfound")
            }
        };


        const findSumElements = (target: number): number[] => {
            //const arr = [1, 2, 4, 8, 16, 32, 64, 128];
            const arr = Object.values(AccessLevel);
            let result: number[] = [];
            let n = arr.length;

            // Use a variable to keep track of whether a valid subset is found
            let found = false;

            // Loop through all possible subsets using bitmasking
            for (let i = 0; i < (1 << n); i++) {
                let sum = 0;
                let subset: number[] = [];

                for (let j = 0; j < n; j++) {
                    if (i & (1 << j)) {
                        sum += arr[j];
                        subset.push(arr[j]);
                    }
                }

                // Check if the current subset sums up to the target
                if (sum === target) {
                    // Update result only if a subset is found
                    result = subset;
                    found = true;
                    break;  // Exit the loop once a valid subset is found
                }
            }

            // Return the result if found, otherwise an empty array
            return found ? result : [];
        };



        fetchData();

    }, [updatedPageID]);

    useEffect(() => {
        // console.log(filterFormData);
        const fetchTableData = async () => {

            if (!reportConfigData) return;
            if (!filterFormData) return;
            if (!paginationData) return;

            try {
                // console.log(filterFormData);
                // let fillData: any[] = [];
                // columnData.map((item: any) => {
                //     filterFormData.map((field: any) => {
                //         if (field.key == item.tableFieldName) {
                //             fillData = filterFormData;
                //             //setdefaultSeacrh([]);
                //         }
                //     })
                // })
               


                const tabledatafromAPI = await userServicer.getReportlist(Number(reportConfigData.pageId), filterFormData, paginationData);
                setTableData(tabledatafromAPI.data);
                setPageCount(tabledatafromAPI.data ? Math.ceil(tabledatafromAPI.data.length / perPage) : 1);
                const filteredColumnData = columnData.filter((item: any) => item.isDisplay === true);
                const filterColumArray = filteredColumnData.map(item => item.tableFieldName).filter(Boolean);
                const updatedfilteredData = tabledatafromAPI.data.map((item: any) =>
                    Object.keys(item)
                        .filter(key => filterColumArray.includes(key))
                        .reduce((obj: any, key: any) => {
                            obj[key] = item[key];
                            return obj;
                        }, {})
                );
                settableColumns(filteredColumnData);
                setCSVData(updatedfilteredData);
            } catch (error) {

            }
        };

        let x1 = typeof reportConfigData
        //console.log(reportConfigData.reportName);

        if (reportConfigData.reportName != undefined) {

            fetchTableData();
        }

    }, [updatedPageID, reportConfigData, filterFormData, refreshData, paginationData]);

    // console.log(filterFormData);

    return (
        <>
            {/* <div  { pageId1 == PageEnums.AreaList && style={{overflowY: 'hidden'}}{"}"}{">"} */}
            <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;{reportConfigData.reportName}</span>
                        {/* <span className="bg-head margin1" >Headquater &nbsp;<GrNext />&nbsp;Headquater List</span> */}
                    </div>
                    <hr className="colorgraph" />
                </div>
                <div className="mani2" style={{marginBottom: 20}}>
                    {updatedPageID == PageEnums.Position && <AddPosition columndata={tableColumns} editRowData={editRowData ? editRowData : ''} sendDatatoParent={setEditRowData} refreshGrid={() => { refreshData ? setRefreshData(false) : setRefreshData(true) }} sendViewStatus={handleViewStatus}/>}
                </div>

                {/* <div className="mani"><EnhancedSearchbar pageID={pageId1} columnData={columnData} sendDataToParent={handleFilterData} sendFilterData={filterFormData} ReportConfigData={reportConfigData} tableData={csvData} csvHeaderColumns={tableColumns} /></div> */}
                {/* <div className="mani">
                    {updatedPageID === String(reportConfigData.pageId) && (<EnhancedSearchbar pageID={updatedPageID} buttonSum={viewButtonSum} columnData={columnData} sendDataToParent={handleFilterData} sendFilterData={ defaultSeacrh1} ReportConfigData={reportConfigData} tableData={csvData} csvHeaderColumns={tableColumns} />)}</div> */}
                <div className="mani"> {reportConfigData.length === undefined ? (<EnhancedSearchbar pageID={updatedPageID} buttonSum={viewButtonSum} columnData={columnData} sendDataToParent={handleFilterData} sendFilterData={defaultSeacrh1} ReportConfigData={reportConfigData} tableData={csvData} csvHeaderColumns={tableColumns} />) : (updatedPageID === String(reportConfigData.pageId) && (<EnhancedSearchbar pageID={updatedPageID} buttonSum={viewButtonSum} columnData={columnData} sendDataToParent={handleFilterData} sendFilterData={defaultSeacrh1} ReportConfigData={reportConfigData} tableData={csvData} csvHeaderColumns={tableColumns} />))}</div> 

                <div className="card-container" >

                    {
                        // tableData.length > 0 ? <EnhancedTable pageID={pageId1} columnData={columnData} tableData1={tableData} sendDataToParent={handleEditButtondata} /> :
                        //     <PageNotFound />

                        //<EnhancedTable pageID={pageId1} buttonSum={viewButtonSum} columnData={columnData} tableData1={tableData} sendDataToParent={handleEditButtondata} sendPaginationDatatoParent={handlePagination} handleViewStatus={isViewStatus} />

                        <EnhancedTable pageID={updatedPageID} tableHeight={'350px'} buttonSum={viewButtonSum} columnData={columnData} tableDatafromParent={tableData} sendDataToParent={handleEditButtondata} sendPaginationDatatoParent={handlePagination} handleViewStatus={isViewStatus} deleteEvent={handleDeleteButtondata} deleteConformationMsg={reportConfigData.reportName} />

                    }


                </div>
            </div>
        </>
    );
}

export default EnhancedPage;




