import AWS from 'aws-sdk';
import { authHeader } from '../Auth/auth-header';
import customToast from '../Commen_Component/Toast/CustomToast';


export const commanServicer = {
    fetchRequest,
    UploadImage,
}



async function fetchRequest(Reqbody: any, AuthStatus: Boolean, endPoint: string, method: string = 'POST') {
    try
    {
        let requestOption: any = {};
    
    if (method === 'GET') {
        requestOption = {
            method: method,
            headers: AuthStatus === true ? { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': authHeader() , 'isweb' : true, 'LanguageId' : 1 } : { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
        }
    }
    else {
        requestOption = {
            method: method,
            headers: AuthStatus === true ? { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': authHeader() , 'isweb' : true, 'LanguageId' : 1 } : { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: Reqbody
        }
    }

    let APIUrl = `${process.env.REACT_APP_API_URL}${endPoint}`;

    let response = await fetch(APIUrl, requestOption);

    if (response.status === 401) {
        return { statusCode: 401 };
    }
    else if (response.status === 400) {
        return { statusCode: 400 };
    }
    else {
        const result = await response.json();
        result.statusCode = 200;
       // console.log('result', result);
        return result;
    }
}
catch
{
    customToast.error("Something went Wrong");
}

}

async function UploadImage(Path: string, DocumentName: string, image: any) {
    

    const spacesEndpoint = String(process.env.REACT_APP_REGION_URL);
    const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_SECREATE_ACCESS_KEY;
    const MainPath = process.env.REACT_APP_MAINPATH;
    //const bucketName = 'ethics-static';
    const bucketName = String(process.env.REACT_APP_BUCKET_NAME);


    
    var s3 = new AWS.S3({
        endpoint: spacesEndpoint,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    });


   
    const photoKey = MainPath + Path + DocumentName;

    var params = {
        ACL: 'public-read',
        Bucket: bucketName,
        Key: photoKey,
        Body: image
    };

    return await s3.upload(params).promise()
        .then((res: any) => {
            console.log(`Upload succeeded - `, res);
            return res.Location;
        })
        .catch((err: any) => {
            console.log("Upload failed:", err);
            return false;
        });

}