import { FaUser } from "react-icons/fa";
import profile from "../../../assets/Images/profile.jpg"
import { Autocomplete, Checkbox, Chip, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, InputLabel, Link, MenuItem, Select, Stack, TextField } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import './User.css';
import Popup from "reactjs-popup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { commanServicer } from "../../Commen_Services/common.services"
import customToast from "../../Commen_Component/Toast/CustomToast";
import AWS from 'aws-sdk';
import { userMasterService } from "../../Commen_Services/userMaster.services";
import TreePositionView from "../../Commen_Component/Popup/TreePositionView";
import BoothMappingDialog from "./BoothMaster/BoothMappingDialog";
import { Booth } from "./BoothMaster/types";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Save } from "@mui/icons-material";


interface userData {
    userName: string,
    passWord: string,
    mobileNo: string,
    roleId: number,
    imagePath: string,
    positionId: number,
    confirmPassword: string,
    positionName: string,
    isActive: boolean,
    electionSabhaLocationId: number,
    id: number,
    lastLogineDate: Date,
    firstName: string,
    middleName: string,
    lastName: string
}
const AddUser = () => {

    const [isActiveToggle, setisActivetoggle] = useState(true);
    const [inputs, setInputs] = useState<userData>({
        userName: '',
        passWord: '',
        mobileNo: '',
        roleId: 0,
        imagePath: '',
        positionId: 0,
        confirmPassword: '',
        positionName: '',
        isActive: true,
        electionSabhaLocationId: 0,
        id: 0,
        lastLogineDate: new Date(),
        firstName:'',
        middleName:'',
        lastName:''
    });
    const [selectedRole, setSelectedRole] = useState();
    const [selectedElectionLocation, setSelectedElectionLocation] = useState();
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [error, setError] = useState({
        userName: '',
        mobileNo: '',
        roleId: '',
        phoneNo: '',
        passWord: '',
        confirmPassword: '',
        imagePath: '',
        positionId: '',
        positionName: '',
        electionSabhaLocationId: '',
        id: '',
        firstName:'',
        middleName:'',
        lastName:''

    });
    const [documentList, setdocumentList] = useState<any[]>([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [disable, setDisable] = useState(false);
    const [dropdownRole, setdropdownRole] = useState([{ OptionID: 0, OptionName: "" }]);
    const [dropdownElectionLocation, setdropdownElectionLocation] = useState([{ OptionID: 0, OptionName: "" }]);
    const navigate = useNavigate();
    const [TreeRadioPopup, setTreeRadioPopup] = React.useState(false);
    const [hierarchy, setHierarchy] = useState();
    const { pageId } = useParams<{ pageId: any }>();
    const { action } = useParams<{ action: string }>();
    const [showBooth, setShowBooth] = useState(false);
    const [tempLocation, setTempLocation] = useState();
    const [isDisabled, setIsDisabled] = useState(false);
    //--------------------------------------------BoothMaster---------------------------------------------------------------
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [booths, setBooths] = useState<Booth[]>([]);
    const [assignedBooths, setAssignedBooths] = useState<Booth[]>([]);
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        const fetchRole = async () => {
            let roleDropDownData = await userMasterService.getRoleDropDownData();
            let roleList = roleDropDownData.data;
            const transformedRole = roleList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(roleList[0])[0]],
                OptionName: item[Object.keys(roleList[0])[1]] == undefined ? item[Object.keys(roleList[0])[2]] : item[Object.keys(roleList[0])[1]]
            }));

            setdropdownRole(transformedRole);

        }

        fetchRole();
    }, [])

    useEffect(() => {
        const fetchLocation = async () => {
            let locationDropDownData = await userMasterService.getElectionLocationDropDownData();
            let locationList = locationDropDownData.data;
            const transformedLocation = locationList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(locationList[0])[0]],
                OptionName: item[Object.keys(locationList[0])[1]] == undefined ? item[Object.keys(locationList[0])[2]] : item[Object.keys(locationList[0])[1]]
            }));

            setdropdownElectionLocation(transformedLocation);
        }
        fetchLocation();
    }, [])

    useEffect(() => {
        if (action === 'view') {
            setDisable(true);
            getData(pageId);
        }
        else if (pageId > 0) {
            getData(pageId);
        }

    }, [])

    useEffect(() => {
        const loadBooths = async () => {
            try {
                const data = await userMasterService.getBoothListByElectionSabhaLocationId(inputs.electionSabhaLocationId);

                setBooths(data.data);
                setShowBooth(false);
            } catch (err) {
                //setError("Failed to load booths");
            } finally {
                setLoading(false);
            }
        };

        loadBooths();
    }, [showBooth, inputs.electionSabhaLocationId]);

    let valRole = { OptionName: '' };

    if (dropdownRole !== undefined && dropdownRole !== null && inputs?.["roleId"] !== undefined && inputs?.["roleId"] !== null) {
        let valRole2 = dropdownRole.filter(item => item.OptionID === inputs?.["roleId"]);

        let valRole4 = valRole2.length > 0 ? Object.values(valRole2[0]) : '';

        valRole.OptionName = valRole4[1] !== undefined ? String(valRole4[1]) : '';

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }

    let valElectionLocation = { OptionName: '' };

    if (dropdownElectionLocation !== undefined && dropdownElectionLocation !== null && inputs?.["electionSabhaLocationId"] !== undefined && inputs?.["electionSabhaLocationId"] !== null) {
        let valElectionLocation2 = dropdownElectionLocation.filter(item => item.OptionID === inputs?.["electionSabhaLocationId"]);

        let valElectionLocation4 = valElectionLocation2.length > 0 ? Object.values(valElectionLocation2[0]) : '';

        valElectionLocation.OptionName = valElectionLocation4[1] !== undefined ? String(valElectionLocation4[1]) : '';

    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }


    const handleFocus = (fieldName: any) => {
        if (action !== 'view') {
            if (fieldName == 'userName') {
                if (!inputs?.userName) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Enter Name " }));
                }

            }
            if (fieldName == 'mobileNo') {
                if (!inputs?.mobileNo) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Enter Mobile Number " }));
                }

            }
            if (fieldName == 'roleId') {
                if (!inputs?.roleId) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Select Role " }));
                }

            }
            if (fieldName == 'passWord') {
                if (!inputs?.passWord) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Enter Password " }));
                }

            }
            if (fieldName == 'confirmPassword') {
                if (!inputs?.confirmPassword) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Enter Confirm Password " }));
                }
            }
            if (fieldName == 'electionSabhaLocationId') {
                if (!inputs?.electionSabhaLocationId) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Select Election Location " }));
                }

            }
            if (fieldName == 'firstName') {
                if (!inputs?.firstName) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Enter First Name " }));
                }

            }
            if (fieldName == 'middleName') {
                if (!inputs?.middleName) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Enter Middle Name " }));
                }

            }
            if (fieldName == 'lastName') {
                if (!inputs?.lastName) {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please Enter Last Name " }));
                }

            }
        }
    };

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {
        navigate("/Report/view/3");
    };

    const handleImageUpload = (e: any) => {
        const file = e.target.files[0];
        setSelectedImage(URL.createObjectURL(file));
        setdocumentList((values: any) => ([...values, { fileData: file, documentName: e.target.name }]));
    };
    useEffect(() => {
        if (Number(pageId) == 0) {
        if (inputs.passWord !== inputs.confirmPassword) {
            setError((prevErrors) => ({ ...prevErrors, confirmPassword: 'Password and Confirm Password Should be Same' }));
        }
        if (inputs.passWord === inputs.confirmPassword) {
            setError((prevErrors) => ({ ...prevErrors, confirmPassword: ''}));
        }  
    }
    
    },[inputs.passWord,inputs.confirmPassword])

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isDisabled) {
            timer = setTimeout(() => {
                setIsDisabled(false);
            }, 5000);
        }

        return () => clearTimeout(timer); 
    }, [isDisabled]);

    const handleToastClick = () => {
        setIsDisabled(true);
        setTimeout(() => {
            setIsDisabled(false);
        }, 5000);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputs((values: any) => ({ ...values, [name]: value }))
        let newError: string;

        // if (name === 'passWord' ) {
        //     if (Number(pageId) == 0) {
        //         if (inputs.passWord !== inputs.confirmPassword) {
        //             setError((prevErrors) => ({ ...prevErrors, confirmPassword: 'Password and Confirm Password Should be Same' }));
        //         }
        //         if (inputs.passWord === inputs.confirmPassword) {
        //             setError((prevErrors) => ({ ...prevErrors, confirmPassword: ''}));
        //         }
        //     }
        // }
        // if (name === 'confirmPassword') {
        //     if (Number(pageId) == 0) {
        //         if (inputs.passWord !== inputs.confirmPassword) {
        //             setError((prevErrors) => ({ ...prevErrors, confirmPassword: 'Password and Confirm Password Should be Same' }));
        //         }
        //         if (inputs.passWord === inputs.confirmPassword) {
        //             setError((prevErrors) => ({ ...prevErrors, confirmPassword: ''}));
        //         }
        //     }
        // }

        if (name === 'userName') {
            // if (!/^[a-zA-Z0-9]{1,50}$/.test(value)) {
            //     newError = "Name should be Alphabetic & maximum of 50 characters";
            // }
            if (value.trim() === '') {
                newError = "Please Enter Name.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, userName: newError }));
        }
        if (name === 'mobileNo') {
            if (value.trim() === '') {
                newError = "Please Enter Mobile Number.";
            }
            else if (!/^[0-9]{10}$/.test(value)) {
                newError = "Mobile Number should be max 10 digits";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, mobileNo: newError }));
        }

        if (name === 'passWord') {
            if (value.trim() === '') {
                newError = "Please Enter password.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, passWord: newError }));
        }
        if (name === 'confirmPassword') {
            if (value.trim() === '') {
                newError = "Please Enter Confirm Password.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, confirmPassword: newError }));
        }
        if (name === 'electionLocationId') {
            if (value.trim() === '') {
                newError = "Please Select Election Location.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, electionLocationId: newError }));  
        }
        if (name === 'positionId') {
            if (value.trim() === '') {
                newError = "Please Assign Position.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, positionId: newError }));  
        }
        if (name === 'firstName') {        
            if (value.trim() === '') {
                newError = "Please Enter First Name.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, firstName: newError }));
        }
        if (name === 'middleName') {           
            if (value.trim() === '') {
                newError = "Please Enter Middle Name.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, middleName: newError }));
        }
        if (name === 'lastName') {      
            if (value.trim() === '') {
                newError = "Please Enter Last Name.";
            }
            else {
                newError = '';
            }
            setError((prevErrors) => ({ ...prevErrors, lastName: newError }));
        }
    }

    const handleChangeforIsActive = ((event: any) => {
        const name = event.target.name;
        const value = event.target.checked;
        setisActivetoggle(value);
        setInputs((values: any) => ({ ...values, [name]: value }));

    })
    const saveData = async (saveId: any) => {
        console.log(inputs);
        // setInputs((values: any) => ({ ...values, id: Number(pageId) }));
        //setInputs((values: any) => ({ ...values, id: pageId }));
        if (Number(pageId) == 0) {
            if (inputs.passWord != inputs.confirmPassword) {
                setError((prevErrors) => ({ ...prevErrors, confirmPassword: 'Password and Confirm Password Should be Same', passWord: 'Password and Confirm Password Should be Same' }));
            }
            if (!inputs?.passWord) {
                setError((prevErrors) => ({ ...prevErrors, passWord: 'Please Enter Password' }));
            }
        }
        if (!inputs.userName) {
            setError((prevErrors) => ({ ...prevErrors, userName: 'Please Enter User Name' }));
        }
        if (!inputs.roleId) {
            setError((prevErrors) => ({ ...prevErrors, roleId: 'Please Select User Role' }));
        }
        if (!inputs.mobileNo) {
            setError((prevErrors) => ({ ...prevErrors, mobileNo: 'Please Enter Mobile No' }));
        }
    
        if (!inputs.electionSabhaLocationId) {
            setError((prevErrors) => ({ ...prevErrors, electionSabhaLocationId: 'Please Select Election Location' }));
        }
        if (!inputs.firstName) {
            setError((prevErrors) => ({ ...prevErrors, firstName: 'Please Enter First Name' }));
        }
        if (!inputs.middleName) {
            setError((prevErrors) => ({ ...prevErrors, middleName: 'Please Enter Middle Name' }));
        }
        if (!inputs.lastName) {
            setError((prevErrors) => ({ ...prevErrors, lastName: 'Please Enter Last Name' }));
        }

        
        if (inputs.roleId != undefined && inputs.userName != undefined && inputs.mobileNo != undefined && inputs.firstName != undefined && inputs.middleName != undefined && inputs.lastName != undefined &&  (pageId == 0 ? inputs.passWord != undefined : true) && assignedBooths.length > 0) {
            if (error.roleId == '' && error.userName == '' && error.mobileNo == '' && error.firstName == '' && error.middleName == '' && error.lastName == '' && (pageId == 0 ? error.passWord == '' : true) && (pageId == 0 ? error.confirmPassword == '' : true)) {
                try {
                    let Objlist: string = "";
                    await Promise.all(documentList.map(async (file1: any) => {
                        let obj2: any = {};
                        let file = file1.fileData;
                        const d2 = new Date();
                        let ImageName = `${d2.getDay()}_${d2.getMonth()}_${d2.getFullYear()}_${d2.getHours()}_${d2.getMinutes()}_${d2.getSeconds()}_${d2.getMilliseconds()}_${file.name}`;
                        let abpath = 'ElectionPortal/User/';
                        let Val1 = await commanServicer.UploadImage(abpath, ImageName, file);
                        Objlist = Val1;
                    }));

                   
                    inputs.imagePath = Objlist;
                    const boothData = removeBoothNo(assignedBooths);
                    const datv = { user: inputs, userWiseBooths: boothData }
                    console.log('df', datv);

                    const res = await userMasterService.saveOrUpdateUserData(datv);

                    if (res.status) {
                        customToast.success(res.message);
                        navigate("/Report/view/3");
                    } else {
                        customToast.Warning(res.message);
                        handleToastClick();
                    }
                } catch (error) {
                    console.error("Error in Submit:", error);
                    customToast.error("Failed to save User.");
                    handleToastClick();
                }
            }
        }
    }

    const removeBoothNo = (booths: Booth[]): Omit<Booth, 'boothNo'>[] => {
        return booths.map(({ boothId, userId }) => ({
            boothId,
            userId,
        }));
    };

    const handleTreeButton = () => {
        setTreeRadioPopup(true);
    }

    const handleChildData = (event: any) => {
        
        let positionId = event;
        if (positionId != null || positionId != undefined) {
            setInputs((values: any) => ({ ...values, 'positionId': positionId }));
            getHierarchy(positionId);
        }
  
    }

    const getHierarchy = async (id: any) => {
        let PostionHierarchy: any;
        if (id > 0) {
            PostionHierarchy = await userMasterService.getPostionHierarchyById(id);
            setHierarchy(PostionHierarchy.data);
            console.log(PostionHierarchy.data);

            setInputs((values: any) => ({ ...values, 'positionName': hierarchy }));
            console.log(PostionHierarchy.data, 'PostionHierarchy');
        }
    }

    const getData = async (id: any) => {
        let UserMasterData = await userMasterService.getUserMasterDataById(pageId);
        console.log(UserMasterData);

        if (UserMasterData.data.id == null || UserMasterData.data.id == undefined) {
            UserMasterData.data.user.id = Number(pageId);
        }
        else {
            UserMasterData.data.user.id = UserMasterData.data.id;
        }

        setInputs(UserMasterData.data.user);
        setHierarchy(UserMasterData.data.user.positionName);
        setSelectedImage(UserMasterData.data.user.imagePath);
        setisActivetoggle(UserMasterData.data.user.isActive);
        setAssignedBooths(UserMasterData.data.boothlist);
        setTempLocation(UserMasterData.data.user.electionSabhaLocationId);

    }

    useEffect(() => {
        if (Number(pageId) > 0) {
            if ((tempLocation != null || tempLocation != undefined) && tempLocation != inputs.electionSabhaLocationId) {
                setAssignedBooths([]);
            }
        }
    }, [inputs.electionSabhaLocationId])

    //--------------------------------------------BoothMaster---------------------------------------------------------------

    const handleDialogOpen = () => {
        if(booths != null || booths != undefined){
            if (booths.length > 0) {
                setDialogOpen(true);
            } else {
                customToast.Warning('The Selected Election Location Does Not Have Booths To Assign');
                handleToastClick();
            }
    }else{
        customToast.Warning('Please Select Election Location ');
        handleToastClick();   
    }

    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleAssign = (booths: Booth[]) => {
        setAssignedBooths(booths);
        console.log(booths);
        handleDialogClose();
    };

    const handleRemoveBooth = (booth: Booth[]) => {
        //setAssignedBooths(assignedBooths.filter((b) => b.boothId !== booth.boothId));
        const boothIdsToRemove = new Set(booth.map(b => b.boothId));
        setAssignedBooths(assignedBooths.filter(b => !boothIdsToRemove.has(b.boothId)));
    };

    const handleDelete = (id: number) => {
        setAssignedBooths(prevChips => prevChips.filter(chip => chip.boothId !== id));
    };

    // if (loading) {
    //     return <CircularProgress />;
    // }

    // if (error) {
    //     //return <div>Error: {error}</div>;
    // }

    console.log(inputs);



    return (
        <>
            <div className="User-Main">
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;{Number(pageId) > 0 && action !== 'view' ? <>Edit </> : action == 'view' ? <>View</> : <>Add </>} User</span>
                    </div>
                    <hr className="colorgraph" />
                </div>

                <div className="my-2" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    borderRadius: '50%',
                }}>
                    {selectedImage ? (
                        <img
                            src={selectedImage}
                            width="120"
                            height={120}
                            className="rounded-circle imaje"
                            alt="Selected"
                            style={{ borderRadius: '50%', }}
                        />
                    ) : (
                        <img
                            src={profile}
                            width="100"
                            className="rounded-circle imaje"
                            alt="Default"
                            style={{ borderRadius: '50%', }}
                        />
                    )}
                </div>
                <div className="prfoile_upload">

                    <label
                        htmlFor="imageupload"
                        className="upload_btn"
                        style={{ fontSize: "14px", justifyContent: 'center' }}
                    >
                        Upload Image
                    </label>
                    <input
                        id="imageupload"
                        type="file"
                        className="upload-input"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={handleImageUpload}
                        disabled={pageId > 0 && action !== 'view' ? false : true}
                    />
                </div>
                <br /><br />
                <div className="user_main">
                    <div className="user_content" >
                        <div className="grid-container-user">
                            <div>

                                <TextField
                                    sx={{ marginRight: 5 }}
                                    id="standard-basic"
                                    variant="standard"
                                    name="userName"
                                    value={inputs?.userName}
                                    label="Name"
                                    onChange={handleChange}
                                    focused={!!inputs?.["userName"]}
                                    onFocus={() => handleFocus("userName")}
                                    error={!!error.userName}
                                    helperText={error.userName}
                                    InputProps={{
                                        readOnly: disable,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '20px',
                                            // width: '400px',
                                            borderColor: error.userName ? 'red' : undefined
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.userName ? 'red' : undefined
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            fontSize: error.userName ? '9px' : '12px',

                                        },
                                    }}
                                />
                            </div>

                            <div>

                                <TextField
                                    sx={{ marginRight: 5 }}
                                    id="standard-basic"
                                    variant="standard"
                                    type="number"
                                    name="mobileNo"
                                    value={inputs?.mobileNo}
                                    label="Mobile No"
                                    onChange={handleChange}
                                    focused={!!inputs?.["mobileNo"]}
                                    onFocus={() => handleFocus("mobileNo")}
                                    error={!!error.mobileNo}
                                    helperText={error.mobileNo}
                                    InputProps={{
                                        readOnly: disable,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '20px',
                                            // width: '400px',
                                            borderColor: error.mobileNo ? 'red' : undefined
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.mobileNo ? 'red' : undefined
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            fontSize: error.mobileNo ? '9px' : '12px',

                                        },
                                    }}
                                />
                            </div>

                            <div className="user_isActive" >
                                <label className="Emp_Switch">
                                    <input disabled={pageId > 0 && action !== 'view' ? false : true} type="checkbox" name="isActive" onChange={handleChangeforIsActive} checked={isActiveToggle} />
                                    <span className="Emp_slider"></span>

                                </label>
                                <span>Is Active</span>

                            </div>

                            <div>
                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={dropdownRole}
                                        value={valRole}
                                        readOnly={disable}
                                        onFocus={() => handleFocus("roleId")}
                                        onChange={(event, newValue: any) => {
                                            setInputs((values: any) => ({ ...values, roleId: newValue != null ? newValue.OptionID : '' }));
                                            setSelectedRole(newValue);
                                            setError((prevErrors) => ({ ...prevErrors, roleId: '' }));
                                        }}
                                        getOptionLabel={(option) => option.OptionName}
                                        getOptionKey={(option) => option.OptionID}
                                        sx={{ width: 400, overflowY: 'auto', overflowX: 'hidden', marginRight: 5 }}
                                        renderInput={(params) => (
                                            <TextField
                                                error={!!error.roleId}
                                                {...params}
                                                label="Role"
                                                variant="standard"
                                                name="roleId"
                                                InputLabelProps={{
                                                    style: {
                                                        color: '#243C5C',
                                                        fontSize: '12px',
                                                        borderColor: 'red',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    {error.roleId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.roleId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.roleId}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div>
                                <FormControl>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={dropdownElectionLocation}
                                        value={valElectionLocation}
                                        readOnly={disable}
                                        onFocus={() => handleFocus("electionSabhaLocationId")}
                                        onChange={(event, newValue: any) => {
                                            setInputs((values: any) => ({ ...values, electionSabhaLocationId: newValue != null ? newValue.OptionID : '' }));
                                            setSelectedElectionLocation(newValue);
                                            setError((prevErrors) => ({ ...prevErrors, electionSabhaLocationId: '' }));
                                            setShowBooth(true);

                                        }}
                                        getOptionLabel={(option) => option.OptionName}
                                        getOptionKey={(option) => option.OptionID}
                                        sx={{ width: 400, overflowY: 'auto', overflowX: 'hidden', marginRight: 5 }}
                                        renderInput={(params) => (
                                            <TextField
                                                error={!!error.electionSabhaLocationId}
                                                {...params}
                                                label="Election Location"
                                                variant="standard"
                                                name="electionLocationId"
                                                InputLabelProps={{
                                                    style: {
                                                        color: '#243C5C',
                                                        fontSize: '12px',
                                                        borderColor: 'red',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    {error.electionSabhaLocationId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.electionSabhaLocationId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.electionSabhaLocationId}</FormHelperText>}
                                </FormControl>
                            </div>                         
                            
                            <div></div>
                            <div>

                                <TextField
                                    sx={{ marginRight: 5 }}
                                    id="standard-basic"
                                    variant="standard"
                                    name="firstName"
                                    value={inputs?.firstName}
                                    label="First Name"
                                    onChange={handleChange}
                                    focused={!!inputs?.["firstName"]}
                                    onFocus={() => handleFocus("firstName")}
                                    error={!!error.firstName}
                                    helperText={error.firstName}
                                    InputProps={{
                                        readOnly: disable,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '20px',
                                            // width: '400px',
                                            borderColor: error.firstName ? 'red' : undefined
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.firstName ? 'red' : undefined
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            fontSize: error.firstName ? '9px' : '12px',

                                        },
                                    }}
                                />
                            </div>

                            <div>

                                <TextField
                                    sx={{ marginRight: 5 }}
                                    id="standard-basic"
                                    variant="standard"
                                    name="middleName"
                                    value={inputs?.middleName}
                                    label="Middle Name"
                                    onChange={handleChange}
                                    focused={!!inputs?.["middleName"]}
                                    onFocus={() => handleFocus("middleName")}
                                    error={!!error.middleName}
                                    helperText={error.middleName}
                                    InputProps={{
                                        readOnly: disable,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '20px',
                                            // width: '400px',
                                            borderColor: error.middleName ? 'red' : undefined
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.middleName ? 'red' : undefined
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            fontSize: error.middleName ? '9px' : '12px',

                                        },
                                    }}
                                />
                            </div>

                            <div>

                                <TextField
                                    sx={{ marginRight: 5 }}
                                    id="standard-basic"
                                    variant="standard"
                                    name="lastName"
                                    value={inputs?.lastName}
                                    label="Last Name"
                                    onChange={handleChange}
                                    focused={!!inputs?.["lastName"]}
                                    onFocus={() => handleFocus("lastName")}
                                    error={!!error.lastName}
                                    helperText={error.lastName}
                                    InputProps={{
                                        readOnly: disable,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '20px',
                                            // width: '400px',
                                            borderColor: error.lastName ? 'red' : undefined
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.lastName ? 'red' : undefined
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            fontSize: error.lastName ? '9px' : '12px',

                                        },
                                    }}
                                />
                            </div>

                            <div className={pageId > 0 ? 'inactive' : ''}>

                                <TextField
                                    sx={{ marginRight: 5 }}
                                    id="standard-basic"
                                    variant="standard"
                                    type="password"
                                    name="passWord"
                                    value={inputs?.passWord}
                                    label="Password"
                                    onChange={handleChange}
                                    focused={!!inputs?.["passWord"]}
                                    onFocus={() => handleFocus("passWord")}
                                    error={!!error.passWord}
                                    helperText={error.passWord}
                                    InputProps={{
                                        readOnly: disable,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '20px',
                                            width: '400px',
                                            borderColor: error.passWord ? 'red' : undefined
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.passWord ? 'red' : undefined
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            fontSize: error.passWord ? '9px' : '12px',

                                        },
                                    }}
                                />
                            </div>

                            <div className={pageId > 0 ? 'inactive' : ''}>

                                <TextField
                                    sx={{ marginRight: 5 }}
                                    id="standard-basic"
                                    variant="standard"
                                    type="password"
                                    name="confirmPassword"
                                    value={inputs?.confirmPassword}
                                    label="Confirm Password"
                                    onChange={handleChange}
                                    focused={!!inputs?.["confirmPassword"]}
                                    onFocus={() => handleFocus("confirmPassword")}
                                    error={!!error.confirmPassword}
                                    helperText={error.confirmPassword}
                                    InputProps={{
                                        readOnly: disable,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '20px',
                                            width: '400px',
                                            borderColor: error.confirmPassword ? 'red' : undefined
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.confirmPassword ? 'red' : undefined
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        style: {
                                            fontSize: error.confirmPassword ? '9px' : '12px',

                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="user_main">
                    <div className="user_content" >
                        <div className="grid-container-user" style={{ marginTop: 15 }}>
                            <div className="position-field">
                                <TextField
                                    sx={{ marginRight: 2, width: '1200px' }}
                                    id="standard-basic"
                                    variant="standard"
                                    name="positionId"
                                    label="Position"
                                    value={hierarchy}
                                    focused={!!inputs?.["positionId"]}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: error.positionId ? 'red' : undefined
                                        },
                                    }}

                                />
                            </div>
                            <button className='btnSave' disabled={action !== 'view' ? false : true} onClick={() => handleTreeButton()} style={{ padding: 19, height: '30px', backgroundColor: '#243C5C', color: 'white', width: 100 }}><Link />&nbsp;Assign Positions</button>&nbsp;&nbsp;
                        </div>
                    </div>
                </div>

                <div className="user_main">
                    <div className="user_content" >
                        <div className="booth_store position-relative">
                            <div className="select_booth_option">
                                <div>
                                    Select Booth :
                                </div>
                                <div>
                                    <button type="button" className="li-btn" disabled={(action == "view" && pageId > 0) || isDisabled} onClick={handleDialogOpen}> <OpenInNewIcon /> </button>
                                </div>
                            </div>
                            <div className="userChips-container">
                                <Stack className="chips-grid-container" spacing={1}>
                                    {assignedBooths.map((chip) => (
                                        <Chip sx={{ backgroundColor: '#d3eafc', border: '1px solid #243c5c', color: '#243c5c', fontWeight: '500'}} label={`Booth No : ${chip.boothNo}`} variant="outlined" onDelete={() => { !(action == "view" && pageId > 0) && handleDelete(chip.boothId) }} />
                                    ))}
                                </Stack>
                            </div>
                        </div>

                    </div>
                </div>
                <Container>
                    <BoothMappingDialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        booths={booths}
                        assignedBooths={assignedBooths}
                        onAssign={handleAssign}
                        onRemoveBooth={handleRemoveBooth}
                    />
                </Container>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '5%', marginTop: 10 }}>
                    {
                        (action !== "view" && pageId > 0) ? <>
                            <button className='btn123' disabled={isDisabled} onClick={(event) => { saveData(inputs) }} style={{ padding: 20, height: '30px', backgroundColor: '#243C5C', color: 'white' }}><Save />&nbsp;&nbsp;Update</button>&nbsp;&nbsp;
                            <button className='btn123' onClick={(event) => { setShowConfirmation(true) }} style={{ padding: 20, height: '30px', marginRight:'95px' }}><Link />&nbsp;Cancel</button>
                        </> : <></>
                    }
                    {
                        (action === "view" && pageId > 0) ? <>
                            <button className='btn123' onClick={(event) => { handleConfirmExit() }} style={{ padding: 20, height: '30px', marginRight:'95px'}}><Link />&nbsp;Back</button>
                        </> : <></>
                    }
                    {
                        (pageId == 0) ? <>
                            <button className='btn123' disabled={isDisabled} onClick={(event) => { saveData(inputs) }} style={{ padding: 20, height: '30px', backgroundColor: '#243C5C', color: 'white' }}><Save />&nbsp;&nbsp;Save</button>&nbsp;&nbsp;
                            <button className='btn123' onClick={(event) => { setShowConfirmation(true) }} style={{ padding: 20, height: '30px' , marginRight:'95px'}}><Link />&nbsp;Cancel</button>
                        </> : <></>
                    }
                    {showConfirmation && (
                        <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                            <div className="confirmation-modal">
                                <p>Are you sure you want to cancel? <br />(Any unsaved changes will be lost.)</p>
                                <div className="canel_btns">
                                    <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
                                    <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Yes, Exit</button>
                                </div>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
            <Popup contentStyle={{ height: 600, width: 600, overflowY: 'auto', backgroundColor: '#2c3e50' }} open={TreeRadioPopup} onClose={() => setTreeRadioPopup(false)}>
                <TreePositionView sendDatatoParent={handleChildData} selectedPositionId={pageId > 0 ? inputs?.positionId : 0} close={(event: any) => setTreeRadioPopup(event)} />
            </Popup>
        </>
    );
}

export default AddUser;



