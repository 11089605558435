import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedPage from '../../Commen_Component/EnhancedPage'; 
import { PositionMasterService } from '../../Commen_Services/Position.service';
import customToast from '../../Commen_Component/Toast/CustomToast';

const View = () => {

    const { pageId } = useParams();
    const [delData, setDelData] = useState();
    const [refTableAfterDel,setRefTableAfterDel] = React.useState(false);
    let id:any;
    let delId: any;
    
    useEffect(() => {
        console.log(delData,'deldata');
        if(delData != undefined || delData != null){
            id = delData;
            delId = id.positionId;
           console.log(pageId,'pageid');
           if(pageId === '4'){
            deletebyId(delId);
           }
        }
        
    },[delData])

    const deletebyId = async (id: any) => {

        let value = await PositionMasterService.deleteById(id);
        if(value)
        {
            customToast.success(value.message);

            refTableAfterDel == true ? setRefTableAfterDel(false) : setRefTableAfterDel(true);
        }
        
    }

    // console.log("PageID" + pageId);
    
    return <EnhancedPage pageId={pageId} deleteData={setDelData} refTable={refTableAfterDel}/>;
};

export default View;




