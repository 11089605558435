import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { FaFilter } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import { userServicer } from "../../Commen_Services/user.services";
import { Autocomplete} from "@mui/material";
import {  InputType } from "../../../_enums/enum.services";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

interface propsHead {
    pageID: string;
    columndata: any[];
    filterformData: ({ key: string; value: any }[]);
    filterFormDataforChips: ({ key: string; value: any }[]);
    //sendDataToParent: ({ key: string; value: any }[]) => void;
    sendDataToParent: (event: { key: string; value: any }[]) => void;
    openClose: any;
    ReportConfigData: RowData[];
    //sendDataToParentforChips: ({ key: string; value: any }[]);
    sendDataToParentforChips: (event: { key: string; value: any }[]) => void;


}

interface RowData {
    [key: string]: any;
}


interface Opt {
    OptionID: string;
    OptionName: string;
}


const Popfilterform: React.FC<propsHead> = ({ pageID, columndata, filterformData,filterFormDataforChips, sendDataToParent, sendDataToParentforChips, openClose, ReportConfigData }) => {


    const [inputs, setInputs] = useState<any>({});
    const [inputsforchips, setInputsforChips] = useState<any>({});
    const [options, setOptions] = useState([]);
    const [dropDownField, setDropDownField] = useState([]);
    const [dp, setDP] = useState<any>([]);
    const [op, setOp] = useState<any>({});
    const [selectedField, setSelectedField] = useState({});

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const handleChange = (event: any) => {
        
        const name = event.target.name;
        const value = event.target.value;
        // console.log(event);
        // console.log(inputs);


        setInputs((values: any) => ({ ...values, [name]: value }))
        // setInputs(Object.entries(inputs).filter(([_, value]) => value !== undefined && value !== ''));

        //setInputsforChips(values => ({ ...values, [name]: value }))
    };

    const handleChangeforChips = (data: { key: string; value: any }) => {
        
        // console.log(data);
        // console.log(inputsforchips);
        

        setInputsforChips((values: any) => ({ ...values, [data.key]: data.value }))
        setInputsforChips((values: any) => ({ ...values, [data.key]: data.value }));

        //setInputsforChips(Object.entries(inputsforchips).filter(([_, value]) => value !== undefined && value !== ''));

    }

    const closePopup = () => {
        openClose(false)

    }

    // const resetForm = () => {
    //     setInputs({});
    // }

    const submitFilterData = (event: any) => {
        
        //event.preventDefault();
        // console.log(inputs);
        
        const transformedData = Object.keys(inputs).map(key => ({
            key: key,
            value: inputs[key]
        }))                                                                    
        const transformedData2 = Object.keys(inputsforchips).map(key => ({
            key: key,
            value: inputsforchips[key]
        }))

        // console.log(transformedData);
        sendDataToParent(transformedData);
        sendDataToParentforChips(transformedData2);

        closePopup();


    }

    const transformFilterData = (formData: ({ key: string; value: any }[])) => {
        return formData.reduce((acc, curr) => {
            acc[curr.key] = curr.value;
            return acc;
        }, {} as Record<string, string>);
    };


    useEffect(() => {
        const dropdownField1 = columndata.find(field => field.autoSearchKey !== null && field.autoSearchKey !== undefined && field.autoSearchKey !== "");
        const dropdownField2: any[] = columndata.filter(item => item.autoSearchKey != null)

        // console.log(dropdownField2);
        // console.log(dropdownField1);
        setDP(dropdownField2);

        if (dropdownField1) {
            setDropDownField((prevValues): any => [...prevValues, dropdownField1.autoSearchKey]);
        }
    }, [columndata])

    useEffect(() => {

        // console.log(filterFormDataforChips);
        const transformedData = transformFilterData(filterFormDataforChips);
        // console.log(transformedData);
        
        setInputsforChips(transformedData);
        
    },[filterFormDataforChips]);



    useEffect(() => {

        const transformedData = transformFilterData(filterformData);
        // console.log(transformedData);
        setInputs(transformedData);
        const fetcOption = async () => {

            const promises = dp.map(async (item: any) => {
                const data = await userServicer.getDropDownlist(item.autoSearchKey, null);
                const h = data.data;
                const transformedData = h.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(h[0])[0]],
                    OptionName: item[Object.keys(h[0])[1]] === undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
                }));
                return { [item.autoSearchKey]: transformedData };
            });

            const results = await Promise.all(promises);
            const newState = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});
            setOp((prevvalue: any) => ({ ...prevvalue, ...newState }));
            // const data = await userServicer.getDropDownlist(dropDownField[0]);
            // const h = data.data;
            // const transformedData = h.map((item: { [x: string]: any; }) => ({
            //     OptionID: item[Object.keys(h[0])[0]],
            //     OptionName: item[Object.keys(h[0])[1]] == undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
            // }));
            // setOptions(transformedData);
        }

        if (dp !== null || dp !== undefined) {
            // console.log(dp);

            
            fetcOption();
        }
    }, [filterformData, dp])

    useEffect(() => {
        // console.log(op);
        let x1: any[] = Object.keys(op);
        x1.map(item => {
            console.log(Object.values(op[item]));
        });
    }, [op]);




    const handleChangeMUI = (event: any) => {
        
        const name = event.target.name;
        const value = event.target.value;
        console.log(name + ":" + value);
        setInputs((values: any) => ({ ...values, [name]: value }))
        setInputsforChips((values: any) => ({ ...values, [name]: value }))

        //setSelectedField(values => ({ ...values, [name]: value }));
    }






    const renderForm1 = () => {


        return (
            <>
                {columndata.map((field, index) => {
                    let c = field.column_name;
                    const autosearh = field.autoSearchKey as string;
                    //console.log(autosearh);

                    if (field.autoSearchKey === null || field.autoSearchKey === undefined || field.autoSearchKey === "") {


                        if (field.fieldType === InputType.Text) {
                            return (
                                <div key={index} className="grid-item">

                                    {/* <TextField id="standard-basic" name={field.tableFieldName} value={field.tableFieldName == filterformData[0].key ? filterformData[0].value : ""} label={field.headerName} type="search" /> */}
                                    <TextField id="standard-basic" variant="standard" name={field.tableFieldName} value={inputs[field.tableFieldName]} label={field.headerName}
                                        //onChange={handleChange}
                                        onChange={(event) => { handleChangeforChips({ key: field.headerName, value: event.target.value }); handleChange(event);  }}

                                        InputProps={{
                                            style: {
                                                color: '#243C5C',
                                                fontSize: '14px',
                                                width: '200px',
                                                borderColor: 'red'
                                            }

                                        }}

                                        InputLabelProps={{
                                            style: {
                                                color: '#243C5C',
                                                fontSize: '12px',
                                                borderColor: 'red',

                                            },
                                        }}

                                    />
                                </div>

                            );

                        }
                        else if (field.fieldType === InputType.Number) {
                            return ( 
                                <TextField
                                    id="filled-number"
                                    label={field.headerName}
                                    type="number"
                                    name={field.tableFieldName}
                                    onChange={(event) => { handleChangeforChips({ key: field.headerName, value: event.target.value }); handleChange(event);  }}
                                    InputProps={{
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '14px',
                                            width: '200px',
                                            borderColor: 'red'
                                        }

                                    }}

                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            color: '#243C5C',
                                            fontSize: '12px',
                                            borderColor: 'red',

                                        },
                                    }}
                                    variant="standard"
                                />

                            );
                        }
                        else if (field.fieldType === InputType.Dropdown) {
                            return (
                                <div key={index} className="col1">
                                    <div className="inputGroup">
                                        <select className="inputtxt" name={field.columnData} onChange={handleChange} >
                                            <option>----- select status ------</option>
                                            <option >Un Approved</option>
                                            <option>Approved</option>
                                            <option>Rejected</option>
                                            <option>dispatched</option>
                                            <option>onhold</option>
                                            {/* {options[dropdownField.column_name].map((option, i) => (
                                        <option value={option.OptionID} key={i}>{option.OptionName}</option>
                                    ))} */}
                                        </select>
                                    </div>
                                </div>
                            );

                        }
                        else if (field.fieldType === InputType.Date) {
                            return (
                                <>
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Select Date"
                                            onChange={(newValue) => console.log(newValue)}
                                            renderInput={(params:any) => (
                                                <TextField
                                                    {...params}
                                                    InputProps={{
                                                        style: { border: 'none' }, // Remove the outline
                                                        onClick: (event) => event.stopPropagation(), // Prevent click event bubbling
                                                    }}
                                                />
                                            )}

                                        />
                                    </LocalizationProvider> */}


                                </>
                            );
                        }

                    }
                    else {
                        //setDropDownField(field.autoSearchKey);

                        // const tempdropdownfield = field.autoSearchKey;
                        // const optiondata = fetcOption1(tempdropdownfield);
                        // const dpvalue = inputs[field.tableFieldName] as string;
                        // console.log(field.tableFieldName);


                        let val3 = { OptionName: '' };
                        
                        if (op !== undefined && op !== null && inputs[field.tableFieldName] !== undefined && inputs[field.tableFieldName] !== null) {
                            // console.log(op);

                            let val2 = op[autosearh]?.filter((item:any) => item.OptionID == inputs[field.tableFieldName]);
                            //let val2 = op["DesignationCode"].filter(item => item.OptionID == inputs[field.tableFieldName]);
                            // console.log(val2);
                            let val4 = val2?.length > 0 ? Object.values(val2[0]) : '';
                            // console.log(val4);
                            val3.OptionName = val4[1] !== undefined ? String(val4[1]) : '';
                        } else {
                            console.log('options or inputs[field.tableFieldName] is undefined or null');
                        }





                        return (
                            <>                          
                                {
                                    Object.keys(op).map(item => {
                                        

                                        if (item === autosearh) {
                                            let arr = []
                                            arr = Object.values(op[item]);
                                            return (
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={arr}
                                                    value={val3}
                                                    onChange={(event, newValue: any) => {


                                                        setInputs((values: any) => ({ ...values, [field.tableFieldName]: newValue != null ? newValue.OptionID : '' }));
                                                        setInputsforChips((values: any) => ({ ...values, [field.headerName]: newValue != null ? newValue.OptionName : '' }));

                                                    }}
                                                    getOptionLabel={(option:any) => option.OptionName}
                                                    getOptionKey={(option:any) => option.OptionID}
                                                    sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden' }}

                                                    renderInput={(params) => (
                                                        <TextField

                                                            {...params}
                                                            label={field.headerName}
                                                            variant="standard"
                                                            name={field.tableFieldName}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: '#243C5C',
                                                                    fontSize: '12px',
                                                                    borderColor: 'red',

                                                                },
                                                            }}

                                                        />
                                                    )}
                                                />
                                            )
                                        }
                                    })

                                }
                            </>
                        );

                    }

                })}
            </>
        );
    }

    const resetForm = () => {
        setInputs({}); 
        
        setInputsforChips({});
        //submitFilterData(null);
        sendDataToParent([]);
        sendDataToParentforChips([]);

    }



    return (
        <>
            <div className="pop-main">
                <div className="pop-head"><span className="pop-form">&nbsp;<FaFilter />&nbsp;&nbsp;{(ReportConfigData as any).reportName} Filter</span></div>

                <div className="pop-form">
                    <form onSubmit={submitFilterData}>
                        <div className="grid-container">
                            {renderForm1()}
                        </div>

                        <div className='btns'>
                            <div>
                                <button className='btn1' onClick={() => { resetForm() }} ><span><GrPowerReset /></span>Reset</button>

                            </div>
                            <div>



                                <button className='btn1' type="submit"><span><FaFilter /></span> Filter</button>&nbsp;
                                <button className='btn1' onClick={closePopup} ><span><IoClose /></span>Close</button>
                            </div>

                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default Popfilterform;