
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound';
import AddUser from '../Admin/UserMaster/AddUser';
import User from '../Admin/UserMaster/User';


const componentPaths: any = {
    "User": User,
    
};

const componentActionPaths: any = {
    "User":AddUser,
}

const MasterRouting2 = () => {
    const { componentName } = useParams<{ componentName: string }>();
    const { action } = useParams<{ action: string }>();
    const { userRight } = useParams<{ userRight: string }>();
    const { pageId } = useParams<{ pageId: string }>();
    

    const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);
    // const [pageId, setPageId] = useState(0);

    const userRightsPath: any = {
        "View": Component, 
        "Edit": Component,
    }

    useEffect(() => {

        const importComponent = async () => {
            if (pageId != null) {
                if (componentName != null) {
                    if (componentActionPaths[componentName]) {
                        setComponent(() => componentActionPaths[componentName]);
                    }
                    else {
                        setComponent(() => null)
                    }

                }   
            }
            else if(action != null)
            {
                if (componentName != null) {
                    if (componentActionPaths[componentName]) {
                        setComponent(() => componentActionPaths[componentName]);
                    }
                    else {
                        setComponent(() => null)
                    }

                }  
            }
            else {
                if (componentName != null) {
                    if (componentPaths[componentName]) {
                        setComponent(() => componentPaths[componentName]);
                    }
                    else {
                        setComponent(() => null)
                    }

                }

            }

        };
        importComponent();
    }, [componentName, pageId]);

    if (!Component) return <PageNotFound />;

    return <Component />

};

export default MasterRouting2;




