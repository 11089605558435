import { commanServicer } from "../Commen_Services/common.services";

export const userMasterService = {
    getRoleDropDownData,
    saveOrUpdateUserData,
    getPostionHierarchyById,
    getUserMasterDataById,
    getElectionLocationDropDownData,
    getBoothListByElectionSabhaLocationId,
}


async function getRoleDropDownData() {
    return await commanServicer.fetchRequest('', true, 'UserMaster/getroledata', 'GET');
}

async function saveOrUpdateUserData(data:any) {
    return await commanServicer.fetchRequest(JSON.stringify(data),true,'UserMaster/saveupdate', 'POST');
}

async function getPostionHierarchyById(id : any) {
    return await commanServicer.fetchRequest('',true,'UserMaster/postionhierarchy?PositionId='+id,'GET');
} 

async function getUserMasterDataById(id : any) {
    return await commanServicer.fetchRequest('',true,'UserMaster/retrive?Id='+id,'GET');
}

async function getElectionLocationDropDownData() {
    return await commanServicer.fetchRequest('', true, 'UserMaster/getelectionlocationdata', 'GET');
}

async function getBoothListByElectionSabhaLocationId(id : any) {
    return await commanServicer.fetchRequest('',true,'UserMaster/boothlist?ElectionSabhaLocationId='+id,'GET');
} 